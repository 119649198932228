import AWS from 'aws-sdk';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
	Button,
	Card,
	CardGroup,
	Divider,
	Dropdown,
	Form,
	Header,
	Icon,
	Image,
	Message,
	Modal,
	Placeholder,
	Segment
} from 'semantic-ui-react';
import { VistaCreate, VistaDelete, VistasList, VistaUploadImage } from './api/vista';
import { addVista, removeVista, setVistas, showCreateVista } from './store/vista';
import { Collection, Vista } from './type';


type VistaCardGroupState = {}
type VistaCardGroupProps = {
	collectionId: string
	vistas: Vista[]
	onSetVistas: (v: Vista[]) => void
}

class VistaCardGroupComponent extends React.Component<VistaCardGroupProps, VistaCardGroupState> {
	componentDidMount() {
		this.listVistas(this.props.collectionId);
	}

	render() {
		return (
			<CardGroup stackable itemsPerRow={3}>
				{
					this.props.vistas.map(vista =>
						<VistaCard key={vista.VistaId} vista={vista} />
					)
				}
			</CardGroup>
		);
	}

	listVistas = (collectionId: string) => {
		VistasList({ CollectionId: this.props.collectionId })
			.then(json => this.props.onSetVistas(json.VistaList))
			.catch(window.alert)
			.finally(() => this.setState({ initialLoad: false }));
	};
}

export const VistaCardGroup = connect(
	state => ({}),
	dispatch => ({
		onSetVistas: (v: Vista[]) => dispatch(setVistas(v))
	})
)(VistaCardGroupComponent);

type VistaCardProps = {
	vista: Vista
}

function VistaCard(props: VistaCardProps) {
	const { vista } = props;

	const [imageLoaded, setImageLoaded] = useState(false);
	const dispatch = useDispatch();

	const [src, setSrc] = useState("");
	const [playing, setPlaying] = useState(false);
	const audio = React.createRef<HTMLAudioElement>();

	const options = [
		{
			key: 'delete',
			icon: 'delete',
			text: 'Delete',
			value: 'delete',
			onClick: () => {
				if (!vista.VistaId) {
					return
				}

				VistaDelete({ VistaId: vista.VistaId })
					.then(() => dispatch(removeVista(vista)))
					.catch(window.alert);
			}
		},
	];

	if (!playing) {
		options.push({
			key: 'play',
			icon: 'play',
			text: 'Play',
			value: 'play',
			onClick: () => {
				if (vista.Description) {
					if (src === "") {
						var speechParams = {
							OutputFormat: "mp3",
							SampleRate: "8000",
							Text: vista.Description,
							TextType: "text",
							VoiceId: "Matthew",
							Engine: "neural"
						};

						// Create the Polly service object
						const polly = new AWS.Polly({ apiVersion: '2016-06-10' });
						polly.synthesizeSpeech(speechParams, function (error, output) {
							if (error) {
								alert(error)
							} else {
								if (output.AudioStream) {
									var uInt8Array = output.AudioStream as Uint8Array;
									var arrayBuffer = uInt8Array.buffer;
									var blob = new Blob([arrayBuffer]);
									var url = URL.createObjectURL(blob);

									setSrc(url);
									setPlaying(true);
								}
							}
						})
					} else {
						audio.current?.play();
						setPlaying(true);
					}
				}
			}
		})
	} else {
		options.push({
			key: 'pause',
			icon: 'pause',
			text: 'Pause',
			value: 'pause',
			onClick: () => {
				if (audio.current) {
					audio.current.pause();
				}
				setPlaying(false);
			}
		})
	}

	return (
		<Card>
			{
				!imageLoaded ?
					<Placeholder>
						<Placeholder.Image rectangular />
					</Placeholder> :
					<></>
			}
			{
				vista.Image && vista.Image.length > 0 ?
					<Image
						style={{
							display: imageLoaded ? 'block' : 'none',
							width: '100%',
							height: 'auto',
							maxHeight: '300px',
							overflow: 'hidden',
							objectFit: 'cover'
						}}
						src={vista.Image}
						alt=''
						size='small'
						onLoad={() => setImageLoaded(true)}
					>
					</Image> :
					<></>
			}
			<Card.Content>
				<div className='hamburger-menu'>
					<Button.Group color='black'>
						<Dropdown
							className='button icon'
							basic
							button
							floating
							options={options}
							icon='bars'
							trigger={<></>}
						/>
					</Button.Group>
				</div>
				<Card.Header content={vista.Name} />
				<Card.Meta content={vista.Name} />
				<Card.Description content={
					!vista.Description ?
						'No description available!' :
						vista.Description.length < 150 ?
							vista.Description :
							vista.Description.slice(0, vista.Description.lastIndexOf(' ', 150)) + '...'
				} />
				<audio
					ref={audio}
					src={src}
					autoPlay
					onEnded={() => setPlaying(false)}
				/>
			</Card.Content>
		</Card>
	);
}

type CreateVistaState = {
	name: string
	desc: string
	collectionId: string
	errors: string[]
	image: File | null
	imageUrl: string | null
}

type CreateVistaProps = {
	open: boolean
	collections: Collection[]
	onOpen: () => void
	onClose: () => void
	onAddVista: (v: Vista) => void
}

class CreateVistaModelComponent extends React.Component<CreateVistaProps, CreateVistaState> {
	state: CreateVistaState = {
		name: '',
		desc: '',
		collectionId: '',
		errors: [],
		image: null,
		imageUrl: null
	};

	render() {
		let collectionOptions = this.props.collections.map(c => ({
			key: c.CollectionId,
			value: c.CollectionId,
			text: c.Name,
		}));
		return (
			<Modal
				onClose={() => {
					this.resetState();
					this.props.onClose();
				}}
				onOpen={() => this.props.onOpen()}
				open={this.props.open}>
				<Modal.Header>Create</Modal.Header>
				<Modal.Content image>
					<Modal.Description>
						<Header>New Lenzo Vista</Header>

						Vistas could be any view or scene that can be portrait in the Lenzo guide.
						A painting or a historical sculpture is an example of Lenzo Vista.

						<Divider />
						<Form>
							{
								this.state.errors.length > 0 ?
									<Message negative
										header="Sorry, we couldn't create the vista!"
										list={this.state.errors} /> : <></>
							}
							<Form.Field required>
								<label>Vista Collection</label>
								<Dropdown
									clearable
									fluid
									search
									selection
									options={collectionOptions}
									placeholder='Select Collection'
									value={this.state.collectionId}
									onChange={(event, data) => this.setState({ collectionId: data.value as string })}
								/>
							</Form.Field>
							<Form.Field required>
								<label>Name</label>
								<input placeholder='Vista Name'
									value={this.state.name}
									onChange={event => this.setState({ name: event.target.value })}
								/>
							</Form.Field>
							<Form.Field>
								<label>Description</label>
								<input placeholder='Vista Description'
									value={this.state.desc}
									onChange={event => this.setState({ desc: event.target.value })}
								/>
							</Form.Field>
							<Form.Field>
								<label>Image</label>
								{
									this.state.imageUrl ?
										<Segment compact={true}>
											<Image src={this.state.imageUrl} size='medium' />
										</Segment>
										:
										<Segment placeholder>
											<Header icon>
												<Icon name='photo' />
											</Header>
											<input
												type="file"
												id="file"
												style={{ display: 'none' }}
												accept="image/png, image/jpeg"
												onChange={this.onImageChange} />
											<Button as="label" htmlFor="file" type="button">
												Upload Image
											</Button>
										</Segment>
								}
							</Form.Field>
						</Form>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button positive={false} onClick={() => {
						this.resetState();
						this.props.onClose();
					}}>
						Discard
					</Button>
					<Button
						positive
						onClick={this.createVista}>
						<Icon name='add' />
						Create
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}

	onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (!files) {
			return;
		}

		for (let i = 0; i < files.length; i++) {
			const file: File = files[i];

			const reader = new FileReader();
			reader.onload = (e: ProgressEvent<FileReader>) => {
				if (!e.target) {
					return;
				}

				this.setState({
					image: file,
					imageUrl: e.target.result as string
				});
			};

			reader.onprogress = (e: ProgressEvent<FileReader>) => {
				console.log(e);
			};

			reader.readAsDataURL(file);
		}
	};

	resetState = () => {
		this.setState({
			name: '',
			desc: '',
			collectionId: '',
			errors: [],
			image: null,
			imageUrl: null,
		});
	};

	createVista: () => void = () => {
		if (!this.validateVista()) {
			return;
		}

		VistaCreate({
			Name: this.state.name,
			Description: this.state.desc,
			CollectionId: this.state.collectionId,
		})
			.then((vista: Vista) => {
				if (this.state.image == null) {
					return vista;
				}

				return VistaUploadImage({
					VistaId: vista.VistaId as string,
					Image: this.state.image
				})
					.then(json => {
						vista.Image = json.ImageUrl;
						return vista;
					});
			})
			.then((vista: Vista) => {
				this.props.onAddVista(vista);
				this.props.onClose();
				this.resetState();
			})
			.catch(reason => this.setState({ errors: [reason.toString()] }));
	};

	validateVista: () => boolean = () => {
		let errors: string[] = [];
		if (this.state.name.length === 0) {
			errors.push('Name is required to create a vista');
		}

		this.setState({ errors });
		return errors.length === 0;
	};
}

export const CreateVistaModel = connect(
	state => ({
		open: state.vistas.showCreate,
		collections: state.collections.list
	}),
	dispatch => ({
		onOpen: () => dispatch(showCreateVista(true)),
		onClose: () => dispatch(showCreateVista(false)),
		onAddVista: (v: Vista) => dispatch(addVista(v))
	})
)(CreateVistaModelComponent);