import Config from "../config";
import { Vista } from "../type";
import { handleError } from "./error";

const API_ADDRESS = Config.apiGateway.address;

type VistasListInput = {
    CollectionId: string
}

export function VistasList(input: VistasListInput) {
    return fetch(`https://${API_ADDRESS}/Prod/collection/${input.CollectionId}/vistas`)
        .then(handleError)
        .then(response => response.json());
}

type VistaCreateInput = {
    Name: string
    Description: string
    CollectionId: string
}

export function VistaCreate(input: VistaCreateInput): Promise<Vista> {
    return fetch(`https://${API_ADDRESS}/Prod/vista`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(input)
    })
        .then(handleError)
        .then(response => response.json());
}

type VistaDeleteInput = {
    VistaId: string
}

export function VistaDelete(input: VistaDeleteInput): Promise<{}> {
    return fetch(`https://${API_ADDRESS}/Prod/vista/${input.VistaId}`, {
        method: 'DELETE'
    })
        .then(handleError)
}

type VistaUploadImageInput = {
    VistaId: string,
    Image: File
}

type VistaUploadImageOutput = {
    ImageUrl: string
}

export function VistaUploadImage(input: VistaUploadImageInput): Promise<VistaUploadImageOutput> {
    return input.Image.arrayBuffer()
        .then(buffer => {
            return fetch(`https://${API_ADDRESS}/Prod/vista/${input.VistaId}/image`, {
                method: 'PUT',
                headers: {
                    'Content-Type': input.Image.type
                },
                body: buffer
            });
        })
        .then(handleError)
        .then(response => response.json());
}