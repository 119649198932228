import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Vista, VistasState } from '../type';
import { DefaultRootState } from 'react-redux';

export const vistaSlice = createSlice({
	name: 'vistas',
	initialState: {
		list: [],
		showCreate: false
	} as VistasState,
	reducers: {
		setVistas: (state, action: PayloadAction<Vista[]>) => {
			state.list = action.payload;
		},
		addVista: (state, action: PayloadAction<Vista>) => {
			state.list.push(action.payload);
		},
		removeVista: (state, action: PayloadAction<Vista>) => {
			state.list = state.list.filter(c => c.VistaId !== action.payload.VistaId);
		},
		showCreateVista: (state, action: PayloadAction<boolean>) => {
			state.showCreate = action.payload;
		}
	}
});

export const {
	setVistas,
	addVista,
	removeVista,
	showCreateVista,
} = vistaSlice.actions;

export const selectVistas = (state: DefaultRootState) => state.vistas.list;
export const selectVista = (state: DefaultRootState, id: string) => state.vistas.list.filter(c => c.VistaId === id).pop();
export const selectShowCreateVista= (state: DefaultRootState) => state.vistas.showCreate;
