import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Collection, CollectionsState } from '../type';
import { DefaultRootState } from 'react-redux';

export const collectionsSlice = createSlice({
	name: 'collections',
	initialState: {
		list: [],
		showCreate: false
	} as CollectionsState,
	reducers: {
		setCollections: (state, action: PayloadAction<Collection[]>) => {
			state.list = action.payload;
		},
		addCollection: (state, action: PayloadAction<Collection>) => {
			state.list.push(action.payload as Collection);
		},
		removeCollection: (state, action: PayloadAction<Collection>) => {
			state.list = state.list.filter(c => c.CollectionId !== action.payload.CollectionId);
		},
		showCreateCollection: (state, action: PayloadAction<boolean>) => {
			state.showCreate = action.payload;
		}
	}
});

export const {
	addCollection,
	removeCollection,
	showCreateCollection,
	setCollections,
} = collectionsSlice.actions;

export const selectCollections = (state: DefaultRootState) => state.collections.list;
export const selectCollection = (state: DefaultRootState, id: string) => state.collections.list.filter(c => c.CollectionId === id).pop();
export const selectShowCreateCollections = (state: DefaultRootState) => state.collections.showCreate;
