export async function handleError(response: Response) {
    if (!response.ok) {
        try {
            const msg = await response.json();
            return Promise.reject(msg.Error || msg)
        } catch (error) {
            return Promise.reject(response.text());
        }
    }

    return response;
}