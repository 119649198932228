import rs, { RSAKey } from 'jsrsasign';
import Config from '../config';
import AWS from 'aws-sdk';

const region = Config.aws.region;
const cognitoAddress = Config.cognito.address;
const appClientId = Config.cognito.appClientId;
const identityPoolId = Config.cognito.identityPoolId;
const userPoolId = Config.cognito.userPoolId;
const loginCallbackURI = Config.cognito.loginCallback;
const logoutCallbackURI = Config.cognito.logoutCallback;

export async function HasAccessToken() {
    const accessToken = window.localStorage.getItem('access_token');
    return accessToken !== null && accessToken !== "";
}

export async function HasRefreshToken() {
    const refreshToken = window.localStorage.getItem('refresh_token');
    return refreshToken !== null && refreshToken !== "";
}

export async function IsAccessTokenExpired() {
    const expires_at = window.localStorage.getItem('expires_at');
    return Date.now() > Number(expires_at)
}

export async function CognitoLogin() {
    // Create random "state"
    const state = getRandomString();
    window.sessionStorage.setItem("pkce_state", state);

    // Create PKCE code verifier
    const code_verifier = getRandomString();
    window.sessionStorage.setItem("code_verifier", code_verifier);

    // Create code challenge
    const arrayHash = await encryptStringWithSHA256(code_verifier)
    var code_challenge = hashToBase64url(arrayHash);
    window.sessionStorage.setItem("code_challenge", code_challenge)

    // Redirtect user-agent to /authorize endpoint
    window.location.href = `https://${cognitoAddress}/oauth2/authorize?response_type=code&state=${state}&client_id=${appClientId}&redirect_uri=${loginCallbackURI}&scope=openid&code_challenge_method=S256&code_challenge=${code_challenge}`;
}

export async function CognitoLogout() {
    // Redirtect user-agent to /logout endpoint
    window.location.href = `https://${cognitoAddress}/logout?client_id=${appClientId}&logout_uri=${logoutCallbackURI}`
}

export function CognitoGetUserInfo() {
    const accessToken = window.localStorage.getItem('access_token');
    return fetch(`https://${cognitoAddress}/oauth2/userInfo`, {
        method: 'post',
        headers: {
            'authorization': 'Bearer ' + accessToken
        }
    })
        .then(handleError)
}

export function CognitoGetToken(code: string, codeVerifier: string) {
    return fetch(`https://${cognitoAddress}/oauth2/token?grant_type=authorization_code&client_id=${appClientId}&code_verifier=${codeVerifier}&redirect_uri=${loginCallbackURI}&code=${code}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
        .then(handleError)
        .then(verifyToken)
}

export function CognitoRefreshToken() {
    const refreshToken = window.localStorage.getItem('refresh_token');
    return fetch(`https://${cognitoAddress}/oauth2/token?grant_type=refresh_token&client_id=${appClientId}&refresh_token=${refreshToken}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
        .then(handleError)
        .then(verifyToken)
}

export function CacheCognitoToken(data: any) {
    // Display tokens
    // console.log(JSON.stringify(parseJWTPayload(data.id_token), null, '\t'))
    // console.log(JSON.stringify(parseJWTPayload(data.access_token), null, '\t'));
    window.localStorage.setItem('id_token', data.id_token)
    window.localStorage.setItem('access_token', data.access_token)
    // Refresh token might not be present in case of the refresh grant_type
    if (data.refresh_token) {
        window.localStorage.setItem('refresh_token', data.refresh_token)
    }

    // Remove 5 minutes as a safe gaurd against scewed time and delays in response.
    window.localStorage.setItem('expires_at', (Date.now() + (Number(data.expires_in) * 1000) - (5 * 60 * 1000)).toString())
}

export function ClearCognitoTokenCache() {
    window.localStorage.removeItem('id_token')
    window.localStorage.removeItem('access_token')
    window.localStorage.removeItem('refresh_token')
    window.localStorage.removeItem('expires_at')
}

export function StoreAWSCredentials() {
    const idToken = window.localStorage.getItem('id_token')
    if (idToken) {
        const login: { [name: string]: string } = {}
        login[`cognito-idp.${region}.amazonaws.com/${userPoolId}`] = idToken
        // Add the User's Id Token to the Cognito credentials login map.

        AWS.config.update({
            region: region,
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: identityPoolId,
                Logins: login
            }),
        });

        (AWS.config?.credentials as AWS.CognitoIdentityCredentials)
            .get(function (err) {
                if (err) {
                    console.log(err);
                }
            });
    }
}

// Generate a Random String
function getRandomString() {
    const randomItems = new Uint32Array(28);
    crypto.getRandomValues(randomItems);
    return randomItems.reduce((acc, item) => `${acc}0${item.toString(16).substr(-2)}`, '');
}

//Encrypt a String with SHA256
function encryptStringWithSHA256(str: string) {
    const PROTOCOL = 'SHA-256';
    const textEncoder = new TextEncoder();
    const encodedData = textEncoder.encode(str);
    return crypto.subtle.digest(PROTOCOL, encodedData);
}

//Convert Hash to Base64-URL
function hashToBase64url(arrayBuffer: ArrayBuffer) {
    const items = new Uint8Array(arrayBuffer);
    const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '');
    const decodedHash = btoa(stringifiedArrayHash);

    const base64URL = decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return base64URL;
}

type JWT = {
    kid: string
    n: string
    e: string
}

// Verify token
async function verifyToken(data: any) {
    const token = data.id_token
    // Get Cognito keys
    const keys_url = `https://cognito-idp.${region}.amazonaws.com/${userPoolId}/.well-known/jwks.json`;
    const resp = await fetch(keys_url).then(handleError)
    var keys: JWT[] = resp['keys'];

    // Get the kid (key id)
    var tokenHeader = parseJWTHeader(token);
    const key_id: string = tokenHeader.kid;

    // Search for the kid key id in the Cognito Keys
    const key = keys.find(key => key.kid === key_id)
    if (key === undefined) {
        throw Error("Public key not found in Cognito jwks.json");
    }

    // verify JWT Signature
    var keyObj = rs.KEYUTIL.getKey(key) as RSAKey;
    var isValid = rs.KJUR.jws.JWS.verifyJWT(token, keyObj, { alg: ["RS256"] });
    if (!isValid) {
        throw Error("Signature verification failed");
    }

    // Verify token has not expired
    var tokenPayload = parseJWTPayload(token);
    if (Date.now() >= tokenPayload.exp * 1000) {
        throw Error("Token expired");
    }

    // Verify app_client_id
    var n = tokenPayload.aud.localeCompare(appClientId)
    if (n !== 0) {
        throw Error("Token was not issued for this audience");
    }

    return data;
};

// Parse JWT Payload
const parseJWTPayload = (token: string) => {
    const [, payload] = token.split('.');
    const jsonPayload = decodePayload(payload)

    return jsonPayload
};

// Parse JWT Header
const parseJWTHeader = (token: string) => {
    const [header,] = token.split('.');
    const jsonHeader = decodePayload(header)

    return jsonHeader
};

// Convert Payload from Base64-URL to JSON
const decodePayload = (payload: string) => {
    const cleanedPayload = payload.replace(/-/g, '+').replace(/_/g, '/');
    const decodedPayload = atob(cleanedPayload)
    const uriEncodedPayload = Array.from(decodedPayload).reduce((acc, char) => {
        const uriEncodedChar = ('00' + char.charCodeAt(0).toString(16)).slice(-2)
        return `${acc}%${uriEncodedChar}`
    }, '')
    const jsonPayload = decodeURIComponent(uriEncodedPayload);

    return JSON.parse(jsonPayload)
}

function handleError(response: Response) {
    if (!response.ok) {
        throw response;
    }

    return response.json();
}