const Config = {
    aws: {
        region: 'us-east-1'
    },
    cognito: {
        address: 'lenzo.auth.us-east-1.amazoncognito.com',
        userPoolId: 'us-east-1_D3EaOuZeF',
        appClientId: '1vg09tu9g13fpe6kp9k27lovi1',
        identityPoolId: 'us-east-1:be3b815e-5b8a-41b9-8f4c-106bb97fb0f4',
        loginCallback: 'https://www.lenzogo.com/login/callback',
        logoutCallback: 'https://www.lenzogo.com/logout/callback'
    },
    apiGateway: {
        address: '3bt20lv9i8.execute-api.us-east-1.amazonaws.com'
    }
}

export default Config;

