import { configureStore } from '@reduxjs/toolkit';
import { collectionsSlice } from './collection';
import { vistaSlice } from './vista';

const store = configureStore({
	reducer: {
		collections: collectionsSlice.reducer,
		vistas: vistaSlice.reducer
	}
});

export default store;