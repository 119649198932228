import Config from "../config";
import { Collection } from "../type";
import { handleError } from "./error";

const API_ADDRESS = Config.apiGateway.address;

export function CollectionsList() {
    return fetch(`https://${API_ADDRESS}/Prod/collections`)
        .then(handleError)
        .then(response => response.json());
}

type CollectionCreateInput = {
    Name: string,
    Description: string,
}

export function CollectionCreate(input: CollectionCreateInput): Promise<Collection> {
    return fetch(`https://${API_ADDRESS}/Prod/collection`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(input)
    })
        .then(handleError)
        .then(response => response.json());
}

type CollectionDeleteInput = {
    CollectionId: string
}

export function CollectionDelete(input: CollectionDeleteInput): Promise<{}> {
    return fetch(`https://${API_ADDRESS}/Prod/collection/${input.CollectionId}`, {
        method: 'DELETE'
    })
        .then(handleError)
}

type CollectionUploadImageInput = {
    CollectionId: string,
    Image: File
}

type CollectionUploadImageOutput = {
    ImageUrl: string
}

export function CollectionUploadImage(input: CollectionUploadImageInput): Promise<CollectionUploadImageOutput> {
    return input.Image.arrayBuffer()
        .then(buffer => {
            return fetch(`https://${API_ADDRESS}/Prod/collection/${input.CollectionId}/image`, {
                method: 'PUT',
                headers: {
                    'Content-Type': input.Image.type
                },
                body: buffer
            });
        })
        .then(handleError)
        .then(response => response.json());
}
