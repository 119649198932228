import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from './store';
import { ProvideAuth, RemoveAuth, SetAuth } from './ProvideAuth';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Route exact path="/login/callback">
				<SetAuth />
			</Route>
			<Route exact path="/logout/callback">
				<RemoveAuth />
			</Route>
			<Route path={["/collections", "/collection"]}>
				<Provider store={store}>
					<ProvideAuth>
						<App />
					</ProvideAuth>
				</Provider>
			</Route>
			<Route exact path="/">
				<Redirect to="/collections" />
			</Route>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
