import React, { useState, useEffect, useContext, createContext, FunctionComponent } from "react";
import { Redirect } from "react-router";
import { Dimmer, Loader } from "semantic-ui-react"

import { CognitoLogin, CognitoLogout, CognitoGetToken, CognitoRefreshToken, CognitoGetUserInfo, CacheCognitoToken, ClearCognitoTokenCache, StoreAWSCredentials, HasAccessToken, IsAccessTokenExpired, HasRefreshToken, } from './api/cognito'

type User = {
    username: string,
    email: string,
}

type ContextProps = {
    authenticated: boolean,
    user: User | undefined,
    login: () => void
    logout: () => void
}

const authContext = createContext<Partial<ContextProps>>({
    authenticated: false,
    user: undefined,
    login: () => { },
    logout: () => { }
});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth: FunctionComponent<{}> = ({ children }) => {
    const [user, setUser] = useState<User>();

    useEffect(() => {
        if (!user) {
            if (HasAccessToken()) {
                if (IsAccessTokenExpired()) {
                    CognitoGetUserInfo()
                        .then(setUser)
                        .then(StoreAWSCredentials);
                } else if (HasRefreshToken()) {
                    CognitoRefreshToken()
                        .then(CacheCognitoToken)
                        .then(CognitoGetUserInfo)
                        .then(setUser)
                        .then(StoreAWSCredentials);
                }
            }
        }
    }, [user])

    const authenticated = false;
    // Return the user object and auth methods
    const auth = {
        authenticated,
        user,
        login: CognitoLogin,
        logout: CognitoLogout
    };

    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const SetAuth: FunctionComponent<{}> = () => {
    var [loading, setLoading] = useState(true)
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');

    useEffect(() => {
        // Verify state matches
        const codeVerifier = window.sessionStorage.getItem('code_verifier');
        if (!code) {
            throw Error("Query paramater 'code' is missing!");
        } else if (!codeVerifier) {
            throw Error("Key 'code_verifier' is not set in the session!");
        } else if (window.sessionStorage.getItem("pkce_state") !== state) {
            alert("Invalid authontication state. Please login again!");
        } else {
            // Fetch OAuth2 tokens from Cognito
            CognitoGetToken(code, codeVerifier)
                .then(CacheCognitoToken)
                .finally(() => loading && setLoading(false));
        }
    }, [code, state, loading])

    return loading ?
        <Dimmer active>
            <Loader active inverted size="massive"></Loader>
        </Dimmer> :
        <Redirect to="/" />
}

export const RemoveAuth: FunctionComponent<{}> = () => {
    useEffect(ClearCognitoTokenCache);

    return <Redirect to="/" />
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};
