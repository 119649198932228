import React from 'react';
import { useDispatch } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';
import {
	Container,
	Divider,
	Dropdown,
	Header,
	Icon,
	Menu,
	Search,
	Sidebar
} from 'semantic-ui-react';
import './App.css';
import { CollectionGroup } from './Collections';
import { useAuth } from './ProvideAuth';
import { showCreateCollection } from './store/collection';
import { showCreateVista } from './store/vista';

function App() {
	const dispatch = useDispatch();
	const auth = useAuth();
	return (
		<Container fluid={true}>
			<Sidebar.Pushable className="App">
				<Sidebar
					as={Menu}
					animation='push'
					icon='labeled'
					inverted
					vertical
					visible={true}
					width='thin'>
					<Menu.Header>
						<div className="lenzo-logo-text">
							<Header as='h1' color="grey" size="huge">Lenzo</Header>
						</div>
					</Menu.Header>
					<Menu.Item as='a' active>
						<Icon name='object group outline' />
						Collections
					</Menu.Item>
					<Menu.Item as='a'>
						<Icon name='photo' />
						Vistas
					</Menu.Item>
				</Sidebar>
				<Sidebar.Pusher className="App-content">
					<Container>
						<Menu text={true} vertical={false}>
							<Menu.Item position="right">
								<Search />
							</Menu.Item>
							<Menu.Item>
								<Dropdown
									icon={<Icon name="plus" size="large" />}
									basic
									floating
									trigger={<></>}
									options={[
										{
											key: 'collection',
											icon: 'object group outline',
											text: 'Collection',
											onClick: () => dispatch(showCreateCollection(true)),
										},
										{
											key: 'vista',
											icon: 'photo',
											text: 'Vista',
											onClick: () => dispatch(showCreateVista(true)),
										},
									]}>
								</Dropdown>
							</Menu.Item>
							<Menu.Item>
								<Dropdown
									icon={<Icon name="user circle" size="large" />}
									basic
									floating
									trigger={<></>}
									options={
										auth.user ?
											[
												{
													key: 'profile',
													icon: 'user',
													text: 'Profile',
												},
												{
													key: 'logout',
													icon: 'sign-out',
													text: 'Logout',
													onClick: auth.logout
												}
											] :
											[
												{
													key: 'login',
													icon: 'sign-in',
													text: 'Login',
													onClick: auth.login
												}
											]
									}
									header={
										auth.user?.username
									}
								>
								</Dropdown>
							</Menu.Item>
						</Menu>
					</Container>
					<Divider />
					<CollectionGroup />
				</Sidebar.Pusher>
			</Sidebar.Pushable>
		</Container>
	);
}

export default App;
