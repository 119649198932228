import React, { useState } from 'react';
import { Link, Route } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
	Button,
	Card,
	Container,
	Divider,
	Dropdown,
	Form,
	Grid,
	GridColumn,
	Header,
	Icon,
	Loader,
	Message,
	Image,
	Modal,
	Placeholder,
	Search,
	Segment,
	Transition,
} from 'semantic-ui-react';

import './Collections.css';
import { CreateVistaModel, VistaCardGroup } from './Vista';

import {
	addCollection,
	removeCollection,
	selectCollections,
	setCollections,
	showCreateCollection
} from './store/collection';
import { selectVistas } from './store/vista';
import { Collection } from './type';
import { CollectionCreate, CollectionDelete, CollectionsList, CollectionUploadImage } from './api/collections';

type CollectionState = {
	initialLoad: boolean
}

type CollectionProps = {
	collections: Collection[]
	onSetCollections: (c: Collection[]) => void
}

class CollectionGroupComponent extends React.Component<CollectionProps, CollectionState> {
	state = {
		initialLoad: true
	};

	componentDidMount() {
		this.listCollections();
	}

	render() {
		return (
			<div className="collections-container">
				<div className="collections-header">
					<Header size="huge">My Collections</Header>
				</div>
				<Route exact path="/collections">
					<CollectionsContent
						loading={this.state.initialLoad} />
				</Route>
				<Route path="/collection/:id"
					render={p => {
						const collection = this.props.collections.find(c => c.CollectionId === p.match.params.id);
						if (!collection) {
							return null;
						}

						return <CollectionDetail collection={collection} />;
					}}>
				</Route>
				<CreateCollectionModal />
				<CreateVistaModel />
			</div>
		);
	}

	listCollections = () => {
		CollectionsList()
			.then(json => this.props.onSetCollections(json.CollectionList))
			.catch(window.alert)
			.finally(() => this.setState({ initialLoad: false }));
	};
}

export const CollectionGroup = connect(
	state => ({
		collections: state.collections.list
	}),
	dispatch => ({
		onSetCollections: (c: Collection[]) => dispatch(setCollections(c)),
	})
)(CollectionGroupComponent);

type CollectionsContentProps = {
	loading: boolean
}

export function CollectionsContent(props: CollectionsContentProps) {
	const collections = useSelector(selectCollections);
	const dispatch = useDispatch();

	return (
		<div className="collections-content">
			<Container>
				{
					props.loading ?
						<Loader active inline='centered' />
						:
						collections && collections.length > 0 ?
							<Card.Group doubling itemsPerRow={4} stackable>
								{
									collections.map(collection =>
										<CollectionCard key={collection.CollectionId}
											collection={collection} />
									)
								}
							</Card.Group>
							:
							<Segment placeholder>
								<Header icon>
									<Icon name='object group' />
									You don't have any collections. Let's create your first
									collection!
								</Header>
								<Button primary
									onClick={() => dispatch(showCreateCollection(true))}>
									Create a Collection
								</Button>
							</Segment>
				}
			</Container>
		</div>
	);
}

type CollectionCardPros = {
	collection: Collection
}

function CollectionCard(props: CollectionCardPros) {
	const { collection } = props;
	const dispatch = useDispatch();

	const options = [
		{
			key: 'delete',
			icon: 'delete',
			text: 'Delete',
			value: 'delete',
			onClick: () => {
				CollectionDelete({
					CollectionId: collection.CollectionId
				})
					.then(() => dispatch(removeCollection(collection)))
					.catch(window.alert) // TODO
			}
		},
	];

	return (
		<Card>
			<Card.Content>
				<div className='hamburger-menu'>
					<Button.Group color='black'>
						<Dropdown
							className='button icon'
							basic
							button
							floating
							options={options}
							icon='bars'
							trigger={<></>}
						/>
					</Button.Group>
				</div>

				<Link to={collection.CollectionId ? '/collection/' + collection.CollectionId : ''}>
					<Card.Header content={collection.Name} />
				</Link>
				<Card.Meta content={'TODO'} />
				<Card.Description content={collection.Description} />
			</Card.Content>
		</Card>
	);
}

type CreateCollectionState = {
	name: string
	desc: string
	errors: string[]
	image: File | null
	imageUrl: string | null
}

type CreateCollectionProps = {
	open: boolean
	onOpen: () => void
	onClose: () => void
	onAddCollection: (c: Collection) => void
}

class CreateCollectionModalComponent extends React.Component<CreateCollectionProps, CreateCollectionState> {

	state: CreateCollectionState = {
		name: '',
		desc: '',
		errors: [],
		image: null,
		imageUrl: null
	};

	render() {
		return (
			<Modal
				onClose={this.props.onClose}
				onOpen={this.props.onOpen}
				open={this.props.open}>
				<Modal.Header>Create</Modal.Header>
				<Modal.Content image>
					<Modal.Description>
						<Header>New Lenzo Collection</Header>
						<Form>
							{
								this.state.errors.length > 0 ?
									<Message negative
										header="Sorry, we couldn't create the collection!"
										list={this.state.errors} /> : <></>
							}
							<Form.Field required>
								<label>Name</label>
								<input placeholder='Collection Name'
									value={this.state.name}
									onChange={event => this.setState({ name: event.target.value })}
								/>
							</Form.Field>
							<Form.Field>
								<label>Description</label>
								<input placeholder='Collection Description'
									value={this.state.desc}
									onChange={event => this.setState({ desc: event.target.value })}
								/>
							</Form.Field>
							<Form.Field>
								<label>Image</label>
								{
									this.state.imageUrl ?
										<Segment compact={true}>
											<Image src={this.state.imageUrl} size='medium' />
										</Segment>
										:
										<Segment placeholder>
											<Header icon>
												<Icon name='photo' />
											</Header>
											<input
												type="file"
												id="file"
												style={{ display: 'none' }}
												accept="image/png, image/jpeg"
												onChange={this.onImageChange} />
											<Button as="label" htmlFor="file" type="button">
												Upload Image
											</Button>
										</Segment>
								}
							</Form.Field>
						</Form>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button positive={false} onClick={() => {
						this.props.onClose();
						this.resetState();
					}}>
						Discard
					</Button>
					<Button
						positive
						onClick={this.createCollection}>
						<Icon name='add' />
						Create
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}

	onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (!files) {
			return;
		}

		for (let i = 0; i < files.length; i++) {
			const file: File = files[i];

			const reader = new FileReader();
			reader.onload = (e: ProgressEvent<FileReader>) => {
				if (!e.target) {
					return;
				}

				this.setState({
					image: file,
					imageUrl: e.target.result as string
				});
			};

			reader.readAsDataURL(file);
		}
	};

	resetState = () => {
		this.setState({
			name: '',
			desc: '',
			errors: [],
			image: null,
			imageUrl: null,
		});
	};

	createCollection: () => void = () => {
		if (!this.validateCollection()) {
			return;
		}

		CollectionCreate({
			Name: this.state.name,
			Description: this.state.desc,
		})
			.then((collection: Collection) => {
				if (this.state.image == null) {
					return collection;
				}

				return CollectionUploadImage({
					CollectionId: collection.CollectionId,
					Image: this.state.image
				})
					.then(resp => {
						collection.Image = resp.ImageUrl;
						return collection;
					})
			})
			.then((collection: Collection) => {
				this.props.onAddCollection(collection);
				this.props.onClose();
				this.resetState();
			})
			.catch(reason => this.setState({ errors: [reason.toString()] }))
	};

	validateCollection: () => boolean = () => {
		let errors: string[] = [];
		if (this.state.name.length === 0) {
			errors.push('Name is required to create a collection');
		}

		this.setState({ errors });
		return errors.length === 0;
	};
}

const CreateCollectionModal = connect(
	state => ({
		open: state.collections.showCreate
	}),
	dispatch => ({
		onOpen: () => dispatch(showCreateCollection(true)),
		onClose: () => dispatch(showCreateCollection(false)),
		onAddCollection: (c: Collection) => dispatch(addCollection(c)),
	})
)(CreateCollectionModalComponent);

type CollectionDetailedProps = {
	collection: Collection,
}

function CollectionDetail(props: CollectionDetailedProps) {
	const vistas = useSelector(selectVistas);
	const [imageLoaded, setImageLoaded] = useState(false);

	const { collection } = props;

	return (
		<Transition
			animation='scale'
			duration={200}
			transitionOnMount={true}>
			<Container>
				<Segment>
					<Link to="/collections">
						<Icon
							className="exit-collection-detail"
							color="grey"
							name="close"
							size="big"
						/>
					</Link>

					<Header as='h2'>{collection.Name}</Header>
					<Grid columns={3}>
						<GridColumn>
							{collection.Description}
						</GridColumn>
						<GridColumn floated="right">
							{
								!imageLoaded ?
									<Placeholder className="collection-photo">
										<Placeholder.Image rectangular />
									</Placeholder> :
									<></>
							}
							{
								collection.Image && collection.Image.length > 0 ?
									<Image className="collection-photo"
										style={{
											display: imageLoaded ? 'block' : 'none',
											width: '100%',
											height: 'auto',
											maxHeight: '300px',
											overflow: 'hidden',
											objectFit: 'cover'
										}}
										src={collection.Image}
										alt=''
										size='small'
										onLoad={() => setImageLoaded(true)}
									/> :
									<></>
							}
						</GridColumn>
					</Grid>
					<Divider />
					<Search />
					<Divider />
					<VistaCardGroup
						collectionId={props.collection.CollectionId}
						vistas={vistas} />
				</Segment>
			</Container>
		</Transition>
	);
}
